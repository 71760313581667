import React, { useRef, useState } from 'react';
import { Menu, Tooltip, Popconfirm } from 'antd';
import { MenuItemProps } from 'antd/lib/menu/MenuItem';
import queryString from 'query-string';
import { useSelector } from 'react-redux';
import { Dictionary } from 'lodash';
// models
import { SelectOption } from '@models/Option';
import { PreselectedFilter } from '@models/filters';
import { SourcingInfo } from './interfaces';
// constants
import { COMPANY_CSV_SIZE_LIMIT } from '@constants/limit';
// services
import { AnalystService } from '@services/api';
// utils
import { mapSortFieldQuery } from '@utils/mapSortFieldQuery';
import { mapSortOrderQuery } from '@utils/mapSortOrderQuery';
import mapFilterToParams from '@utils/filters/mapFiltersToURLParams';
import { exportFileCSV } from '@utils/csvHandler';
// redux
import { selectors as outreachSearchSelectors } from '@redux/company-outreach/search';
import { selectors as searchCountSelectors } from '@features/grid/search-count';
import { selectors as searchSelectors } from '@features/grid/search';
import { selectors as filterSourcingSelectors } from '@redux/company-outreach/filters';
import { selectors as filterSourcesSelectors } from '@redux/company/filters';
import { getViewId } from '@optx/features/grid/view/state/selectors';
// components
import { columns } from '@components/common/table/Companies/columns/virtual-column-config';
import { SOURCING_OUTREACH_SUMMARY_REPORT_DETAILS } from '@optx/constants/exportedFileName';

interface RedirectSavedSearchesMenuItemProps {
  hideDropdown?: () => void;
}

const RedirectSavedSearchesMenuItem = ({
  hideDropdown,
  onClick,
  ...restProps
}: MenuItemProps & RedirectSavedSearchesMenuItemProps) => {
  const searchCompanyCount = useSelector(searchCountSelectors.getSearchCount('outreach'));
  const analystId = useRef<any>();
  const columnName = useRef<any>();
  const newFilters = useRef<{ queryParsed: queryString.ParsedQuery<string> }>();
  const analystIdFilter = useSelector(
    filterSourcingSelectors.getSourcingOptitonsFilterValues('analyst_id')
  );
  const columnNameFilter = useSelector(
    filterSourcingSelectors.getSourcingOptitonsFilterValues('column_name')
  );
  const dateValueFilter = useSelector(filterSourcingSelectors.getSourcingFilterOptionsDateValue);
  const searchHasValues = useSelector(outreachSearchSelectors.searchHasValues);
  const searchKey = useSelector(searchSelectors.getSearchKey('outreach'));
  const filter = useSelector(outreachSearchSelectors.getFilters);
  const tableGridQuery = useSelector(searchSelectors.getTableGridQuery('outreach'));
  const sortBy = useSelector(outreachSearchSelectors.getSorting);
  const filtersMap = useSelector(filterSourcesSelectors.getFiltersMap);
  const listType = useSelector(searchSelectors.getListType('outreach'));
  const id = useSelector(getViewId('outreach'));
  const [isLoading, setIsLoading] = useState(false);

  if (tableGridQuery) {
    const queryParsed = queryString.parse(tableGridQuery);
    const filterGridQuery = { ...filter, queryParsed };
    analystId.current = queryParsed.analyst_id;
    columnName.current = queryParsed.column_name;
    const customFilterGridQuery = { ...filterGridQuery, ddate: dateValueFilter };
    newFilters.current = customFilterGridQuery;
  }

  const sourcingOutReachInfo: SourcingInfo = {
    analyst_id:
      (analystIdFilter as unknown as Array<SelectOption<string>>)?.map(list => list.value) ||
      analystId.current,
    column_name: (columnNameFilter as SelectOption<string>)?.value || columnName.current,
  };

  if (!searchHasValues) {
    return null;
  }

  const handleExportCSV = (includeDescription: boolean) => {
    const params = mapFilterToParams(
      filtersMap,
      newFilters.current as unknown as Dictionary<PreselectedFilter>
    );

    const parameterFilter = {
      ...params,
      ...(searchKey ? { query: searchKey } : {}),
      ...(listType === 'combined' ? { combined_search_id: id } : {}),
      sort_fields: sortBy.map(mapSortFieldQuery),
      sort_orders: sortBy.map(mapSortOrderQuery),
    };

    setIsLoading(true);

    exportFileCSV(
      SOURCING_OUTREACH_SUMMARY_REPORT_DETAILS,
      parameterFilter,
      columns,
      AnalystService.exportCompaniesOutReachToCSV,
      includeDescription,
      undefined,
      sourcingOutReachInfo
    ).then(() => {
      setIsLoading(false);
    });
  };

  const disabled = !searchCompanyCount || searchCompanyCount > COMPANY_CSV_SIZE_LIMIT || isLoading;

  const handleClick = (includeDescription = false) => {
    if (!disabled) {
      handleExportCSV(includeDescription);
      setTimeout(() => {
        hideDropdown && hideDropdown();
      }, 1000);
    }
  };

  //   wrap Menu.Item in span to show tooltip on hovering while Menu.Item is disabled.
  return (
    <Tooltip title="Up to 100K rows" placement="left">
      <span>
        <Popconfirm
          title="Include the description?"
          placement="bottom"
          onConfirm={() => handleClick(true)}
          overlayStyle={{ position: 'fixed' }}
          onCancel={() => handleClick()}
          okText="Yes"
          cancelText="No"
        >
          <Menu.Item disabled={disabled} key="export-to-csv" {...restProps}>
            {isLoading ? 'Exporting' : 'Export to .csv'}
          </Menu.Item>
        </Popconfirm>
      </span>
    </Tooltip>
  );
};

export default React.memo(RedirectSavedSearchesMenuItem);
