import queryString from 'query-string';
import { Dictionary } from 'lodash';
import { UserInformation, RegionOptions } from '@optx/models/user';
import { CompanyNotification, CompanyUserValue } from '@optx/models/Company';
import { NotesSearchQuery } from '@optx/redux/company/notes/search/interfaces';
import { AxiosRequestConfig } from 'axios';
import { AddTagPayload } from '@optx/redux/company/tags/interfaces';
import { ShareList } from '@optx/redux/user/share/interfaces';
import { CombineSearch } from '@optx/features/combine-search/state/interfaces';
import { ShareListRequest } from '@optx/models/feature/shareList/ShareListRequest';
import { UpdateUserSettingsPayload } from '@optx/models/api/user';
// utils
import { cleanPayload } from '@utils/api/cleanPayload';
import { mapSearchCounterQuery, mapSortQuery, mapPaginationQuery } from '@optx/utils/search';
import { EditDateFields } from '@optx/components/feature/company-individual-edit/state/date-fields/interfaces';
import { UserListPayload } from '@optx/redux/user/user-list/interfaces';
import {
  CustomValue,
  UpdateFieldsPayload,
} from '@optx/features/company/edit-fields/state/interfaces';
import { SortByRule } from '@optx/models/table/sorting';
import { PageInfo } from '@optx/models/table/Pagination';
import { SaveResearchRationalePayload } from '@optx/features/add-research-rationale/state/interfaces';
import { AddCompanyReviewPayload } from '@optx/features/company-review/state/interfaces';

import { SelectOption } from '@optx/models/Option';
import ApiClient, { API_URL } from './axios-client';
import { UpdateListAssociation, PublicWatchListActionPayload } from '@optx/models/WatchList';

class UserService {
  static client: ApiClient = new ApiClient(`${API_URL}/user`);

  // user info
  static getUserInfo() {
    return UserService.client.get<UserInformation>('');
  }

  static updateSettings(settings: Partial<UpdateUserSettingsPayload> = {}) {
    return UserService.client.put<void>('/settings', settings);
  }

  static updateProfilePicture(picture: Blob) {
    const data = new FormData();
    data.append('image', picture);

    const config: AxiosRequestConfig = {
      headers: {
        'content-type': 'multipart/form-data',
      },
      responseType: 'blob',
    };

    return UserService.client.post('/save_img', data, config);
  }

  static resetPassword(currentPassword: string, nextPassword: string) {
    const data = {
      password: currentPassword,
      new_password: nextPassword,
    };

    return UserService.client.post(`/change_password?${queryString.stringify(data)}`);
  }

  static getRegionOptions() {
    return UserService.client.get<Array<RegionOptions>>('/region_settings');
  }

  // notes
  static getNotes(companyId?: number) {
    const payload = companyId === undefined ? {} : { company_id: companyId };

    return UserService.client.get(`/notes?${queryString.stringify(payload)}`);
  }

  static searchNotes(queryData: NotesSearchQuery) {
    const payload = {
      query: queryData.query,
      page: queryData.pageNumber,
      per_page: queryData.pageSize,
    };
    const query = queryString.stringify(payload);

    return UserService.client.get(`/notes?${query}`);
  }

  static createCompanyNote = (companyId: number, title: string, noteText: string) => {
    const payload = {
      company_id: companyId,
      title,
      note_text: noteText,
    };

    return UserService.client.post('/notes', payload);
  };

  static updateCompanyNote(note: CompanyNotification, noteText: string) {
    const payload = {
      title: note.note_title,
      unique_id: note.note_unique_id,
      note_text: noteText,
    };

    return UserService.client.put('/notes', payload);
  }

  static updateOpsTeam = (payload: UpdateFieldsPayload) => {
    const membersName = (payload.value as SelectOption<string>[]).map(members => members.value);
    const customPayload = {
      company_id: payload.companyId,
      user_values: { ops_team: membersName },
    };

    return UserService.client.post('/company_info', customPayload);
  };

  static updateDealTeam = (payload: UpdateFieldsPayload) => {
    const dealTeamValues = (payload.value as Array<SelectOption>).map(item => ({
      value: Number(item.value),
      label: item.label,
    }));
    const customPayload = {
      company_id: payload.companyId,
      user_values: { deal_team: dealTeamValues },
    };

    return UserService.client.post('/company_info', customPayload);
  };

  static updateCompanyInfo = (data: EditDateFields) => {
    const { companyId, ...rest } = data as EditDateFields;

    const customData = data as UpdateFieldsPayload;

    const fieldKey = Object.keys(rest)[0];
    const value = Object.values(rest)[0];

    // check format date

    const payload = {
      company_id: customData.companyId,
      user_values: {
        [customData.fieldKey ? (customData.fieldKey as string) : fieldKey]: customData.value
          ? customData.value
          : value,
      },
    };

    return UserService.client.post('/company_info', payload);
  };

  static updateSeniorDealTeamLead = (payload: UpdateFieldsPayload) => {
    const seniorsDealTeamList = (payload.value as Array<SelectOption>).map(item => item.value);
    const customPayload = {
      company_id: payload.companyId,
      user_values: { senior_deal_team_lead: seniorsDealTeamList },
    };

    return UserService.client.post('/company_info', customPayload);
  };

  static deleteCompanyNote = (noteId: number) => {
    const payload = {
      note_id: noteId,
    };

    return UserService.client.delete(`/notes?${queryString.stringify(payload)}`);
  };

  // favorites
  static getFavorites = () => {
    const query = 'company_id';

    return UserService.client.get(`/favorites?query=${query}`);
  };

  static addFavorite = (companyId: number) =>
    UserService.client.put('/favorites', { company_id: companyId });

  static deleteFavorite = (companyId: number) => {
    const payload = {
      company_id: companyId,
    };

    return UserService.client.delete(`/favorites?${queryString.stringify(payload)}`);
  };

  // evaluation
  static updateFitEvaluation = (payload: UpdateFieldsPayload) =>
    UserService.client.post('/interesting/edit_history', {
      company_id: payload.companyId,
      is_interesting: payload.value,
      rationale: payload.rationale,
    });

  // alerts
  static getAlerts = (history?: boolean, pageNr?: number, pageSize = 25, sorting?: string) => {
    const payload = {
      history,
      page: pageNr,
      per_page: pageSize,
      sorting,
    };

    return UserService.client.get(`/alerts?${queryString.stringify(payload)}`);
  };

  static updateAlerts = (ids: Array<number>) =>
    UserService.client.put(
      '/alerts',
      queryString.stringify({ alert_id: ids }, { arrayFormat: 'comma' })
    );

  static dismissAlert = (id: number) => {
    UserService.client.put('/alerts/history', queryString.stringify({ alert_id: id }));
  };

  // tags
  static addCompanyTag = (data: AddTagPayload) => {
    const payload = {
      company_id: data.companyId,
      name: data.name,
    };

    return UserService.client.post('/company_tag', payload);
  };

  // disable user
  static disableUser(id: number) {
    const data = {
      id,
      status: 'No Access',
    };

    return UserService.client.patch(`?${queryString.stringify(data, { arrayFormat: 'comma' })}`);
  }

  // unlock user
  static unlockUser(id: number) {
    const data = { user_id: id };

    return UserService.client.put(`/unlock_user_account?${queryString.stringify(data)}`);
  }

  // get user list
  static fetchUserList(queryPayload: UserListPayload, isFetchPSG?: boolean) {
    const query = queryString.stringify(queryPayload);

    return UserService.client.get(
      `/list?${query}${isFetchPSG ? '&psg=True' : '&senior_advisor=true'}`
    );
  }

  // share user list
  static shareList(shareData: ShareList | ShareListRequest) {
    return UserService.client.post('/share_list', shareData);
  }

  static publicList(payload: PublicWatchListActionPayload) {
    return UserService.client.post('/public_list', payload);
  }

  static getShareListInfo(listId: number) {
    const queryPayload = {
      list_id: listId,
    };
    const query = queryString.stringify(queryPayload);

    return UserService.client.get(`/list/share_menu?${query}`);
  }

  // software evaluation
  static addCompanySoftwareEvaluation = (payload: UpdateFieldsPayload) => {
    const queryData = {
      company_id: payload.companyId,
      evaluation: payload.value,
      rationale: payload.rationale,
    };

    return UserService.client.post(
      `/software_company_evaluation?${queryString.stringify(queryData)}`
    );
  };

  // business evaluation
  static addCompanyInBusinessEvaluation = (payload: UpdateFieldsPayload) => {
    const queryData = {
      company_id: payload.companyId,
      evaluation: payload.value,
    };

    // need to update endpoint
    return UserService.client.post(`/business_evaluation?${queryString.stringify(queryData)}`);
  };

  // update company user values
  static updateCompanyUserValues(payload: CompanyUserValue | UpdateFieldsPayload) {
    let fieldKey = payload.fieldKey;
    let financialYearKey;

    if (payload.fieldKey === 'revenue_list') {
      fieldKey = 'last_rev_update_amount';
      financialYearKey = 'last_rev_update_year';
    }

    if (payload.fieldKey === 'ebitda_list') {
      fieldKey = 'ebitda_amount';
      financialYearKey = 'ebitda_year';
    }

    const financialValue = (payload as UpdateFieldsPayload).value as CustomValue;
    const data = {
      company_id: payload.companyId,
      user_values: {
        [fieldKey!]:
          payload.fieldKey === 'revenue_list' || payload.fieldKey === 'ebitda_list'
            ? financialValue.value
            : payload.value,
        ...(payload.lastRoundData ?? {}),
        ...(financialYearKey && { [financialYearKey]: payload.year }),
      },
    };

    return UserService.client.post('/company_info', data);
  }

  // get cards data for "My Companies" section under "Manage My Pipeline"
  static fetchMyCompaniesCards = (
    searchKey: string,
    filter: Dictionary<undefined | string | (string | number)[]>,
    selectedCard?: string
  ) => {
    const payload = mapSearchCounterQuery(searchKey, filter);

    if (selectedCard) {
      payload.selected_card = selectedCard;
    }

    const updatedPayload = cleanPayload(payload);

    return UserService.client.post('/pipeline', updatedPayload);
  };

  // bulk Watchlist

  static bulkAddToWatchlist = (
    companyIds: number[],
    existingListIds: number[],
    newTitles: string[]
  ) => {
    const payload = {
      company_ids: companyIds,
      existing_list_ids: existingListIds,
      new_titles: newTitles,
    };

    return UserService.client.put('/lists/favorites_bulk/', payload);
  };

  static bulkRemoveFromWatchList = (companyIds: number[], listId: string) => {
    const payload = {
      company_id: companyIds,
      list_id: listId,
    };

    return UserService.client.delete('/bulk_delete_companies_from_list', { data: payload });
  };

  static bulkMergeCompanies = (companyIds: number[]) => {
    const payload = {
      company_ids: companyIds,
    };

    return UserService.client.post('/merge_companies', payload);
  };

  // bulk software evaluation
  static bulkCompanySoftwareEvaluation = (
    companyId: number[] | string[],
    isSoftware = false,
    rationale?: string
  ) => {
    const queryData = {
      company_ids: companyId,
      evaluation: isSoftware,
      rationale,
    };

    return UserService.client.post('/bulk_software_company_evaluation', queryData);
  };

  static updateActive = (payload: UpdateFieldsPayload) => {
    const active = payload.value;
    const customPayload = {
      company_id: payload.companyId,
      user_values: { active },
    };

    return UserService.client.post('/company_info', customPayload);
  };

  static removeSharedList(listId: number | string) {
    const queryPayload = {
      list_id: listId,
    };

    return UserService.client.delete(`/remove_shared_list?${queryString.stringify(queryPayload)}`);
  }

  static getScheduledTouchesDashboard() {
    return UserService.client.get('/user_touches/data_settings');
  }

  static getScheduledTouches(
    tab: string,
    startDate: string,
    endDate: string,
    sortBy: Array<SortByRule<any>>,
    pagination: PageInfo,
    touchType: string,
    stageType: string,
    rankType: string
  ) {
    const sort = mapSortQuery(sortBy);
    const pag = mapPaginationQuery(pagination);

    const queryPayload = {
      ...sort,
      ...pag,
      start_date: startDate,
      end_date: endDate,
      tab: tab !== '*' ? tab : undefined,
      touch_type: touchType !== '*' ? touchType : undefined,
      stage_type: stageType !== '*' ? stageType : undefined,
      pipeline_rank: rankType !== 'All' ? Number(rankType) : undefined,
    };

    return UserService.client.get(`/user_touches?${queryString.stringify(queryPayload)}`);
  }

  static getScheduledTouchesCount(
    startDate: string,
    endDate: string,
    touchType: string,
    stageType: string,
    rankType: string
  ) {
    const queryPayload = {
      start_date: startDate,
      end_date: endDate,
      touch_type: touchType !== '*' ? touchType : undefined,
      stage_type: stageType !== '*' ? stageType : undefined,
      pipeline_rank: rankType !== 'All' ? Number(rankType) : undefined,
    };

    return UserService.client.get(`/user_touches/count?${queryString.stringify(queryPayload)}`);
  }

  static unmergeCompanies = (mainCompanyId: number, companyIds: number[], copyData: boolean) => {
    const payload = {
      main_company_id: mainCompanyId,
      source_companies_ids: companyIds,
      copy_data: copyData,
    };

    return UserService.client.post('/unmerge_companies', payload);
  };

  static sendOptxScoreRationale = (
    companyId: number,
    rationale: string | null,
    optxScoreVerified: boolean | null,
    rationaleReasonLower: string | null,
    rationaleReasonUpper: string | null,
    scoreType: string
  ) => {
    const payload = {
      company_id: companyId,
      rationale,
      optx_score_verified: optxScoreVerified,
      rationale_reason_lower: rationaleReasonLower,
      rationale_reason_upper: rationaleReasonUpper,
      score_type: scoreType,
    };

    return UserService.client.post('/optx_score_rationale', payload);
  };

  static updateWebsiteActive = (payload: UpdateFieldsPayload) => {
    const customPayload = {
      company_id: payload.companyId,
      user_values: { url_status: payload.value },
    };

    return UserService.client.post('/company_info', customPayload);
  };

  static sendResearchRationale = (payload: SaveResearchRationalePayload) => {
    return UserService.client.post('/save_all_rationale', payload);
  };

  static updateNextSteps = (payload: UpdateFieldsPayload) => {
    let extraPayload = {};
    const { extraValues, extraKeys } = payload;

    if (extraValues !== undefined && extraKeys?.length && extraValues?.length) {
      extraKeys.forEach((key, index) => {
        extraPayload = {
          ...extraPayload,
          ...(extraValues[index] ? { [key]: extraValues[index] } : {}),
        };
      });
    }

    const data = {
      company_id: payload.companyId,
      user_values: {
        next_steps: ((payload as UpdateFieldsPayload).value as CustomValue).value,
        ...extraPayload,
      },
    };

    return UserService.client.post('/company_info', data);
  };

  // Sub Vertical
  static subVertical = (payload: UpdateFieldsPayload) => {
    const data = {
      company_id: payload.companyId,
      user_values: {
        sub_vertical: payload.value as string[],
      },
    };

    return UserService.client.post('/company_info', data);
  };

  static updateNote = (payload: { companyId: number; note: string }) => {
    const data = {
      company_id: payload.companyId,
      user_values: { company_notes: payload.note },
    };

    return UserService.client.post('/company_info', data);
  };

  static updateLastRaisedAmount = (payload: UpdateFieldsPayload) => {
    const data = {
      company_id: payload.companyId,
      user_values: { ...((payload as UpdateFieldsPayload).value as CustomValue) },
    };

    return UserService.client.post('/company_info', data);
  };

  static updateAiMl = (payload: UpdateFieldsPayload) => {
    const data = {
      company_ids: payload.companyId,
      evaluation: payload.value,
    };

    return UserService.client.post('/ai_ml_evaluation', data);
  };

  // bulk AI / ML
  static bulkCompanyAiMl = (companyId: number[], isAiMl: boolean | null) => {
    const queryData = {
      company_ids: companyId,
      evaluation: isAiMl,
      is_bulk_action: true,
    };

    return UserService.client.post('/ai_ml_evaluation', queryData);
  };

  // review company
  static reviewCompany = (payload: AddCompanyReviewPayload) => {
    const customPayload = {
      company_id: payload.companyId,
      user_id: payload.userId,
    };

    return UserService.client.post('/company_review', customPayload);
  };

  static updateFavoriteListAddon(payload: UpdateListAssociation) {
    const query = { list_id: payload.listId, addon_company: payload.addonCompany };

    return UserService.client.put(`/set_addon_for_list_companies?${queryString.stringify(query)}`);
  }

  static createCombineSearch = (payload: CombineSearch) => {
    return UserService.client.post('/combined_search', payload);
  };
}

export default UserService;
