import React, { useState, useEffect, useCallback } from 'react';
import { FieldHelperProps, FormikProps, useField } from 'formik';
import { Row, Col, Typography } from 'antd';
import { FormItem, Input, Select } from 'formik-antd';
import { ValueType } from 'react-select';
import { debounce } from 'lodash';
// models
import { SelectOption } from '@models/Option';
import { FormValues } from '../models';
// constants
import { SEARCH_MINIMUM_CHAR_COUNT } from '@constants/search';
import { FORM_INITIAL_VALUES } from '../constants';
// hooks
import useLoadOptions from '../hooks/useLoadOptions';
import useGetFilterData from '../hooks/useGetFilterData';
// components
import InputNumberField from './fields/InputNumberField';
import DropdownIndicator from './DropdownIndicator';
import AsyncSingleSelect from '@optx/shared/view/molecules/Select/AsyncSingleSelect';
import ProductCategoryField from './fields/ProductCategoryField';
// styled
import { Styled } from './AddCompanyModal.styled';

const { Option } = Select;

interface Step3Props {
  formikProps: FormikProps<FormValues>;
  handleDisableNextButton: (disabled: boolean) => void;
}

const areFieldsValid = (formikProps: FormikProps<FormValues>) => {
  const errors = formikProps.errors;

  if (
    errors.companyName ||
    errors.primaryUrl ||
    errors.primaryAddress ||
    errors.country ||
    errors.state ||
    errors.city ||
    errors.secondaryAddress ||
    errors.secondaryCountry ||
    errors.secondaryState ||
    errors.secondaryCity ||
    errors.sector
  )
    return false;

  return true;
};

const resetField = (
  fieldValue: SelectOption | undefined,
  helper: FieldHelperProps<SelectOption | undefined>,
  initialValue: SelectOption
) => {
  if (fieldValue) {
    helper.setValue(initialValue);
  }
};

/**
 * General Company Info.
 *
 * In Step 3 we handle some fields, including primary address (and secondary one too!).
 * Secondary address isn't available at the moment because of client's request, but it will be
 * in the future. When this happens, just uncomment some lines, the code is already there.
 */
const AddCompanyStep3: React.FC<Step3Props> = ({ formikProps, handleDisableNextButton }) => {
  // loaders
  const [isLoadingState, setIsLoadingState] = useState<boolean>(false);
  const [isLoadingCity, setIsLoadingCity] = useState<boolean>(false);
  const [isLoadingSecondaryState, setIsLoadingSecondaryState] = useState<boolean>(false);
  const [isLoadingSecondaryCity, setIsLoadingSecondaryCity] = useState<boolean>(false);

  const [hasSecondaryAddressField] = useField<boolean>('hasSecondaryAddress');
  const [cityField, , cityHelpers] = useField<SelectOption | undefined>('city');
  const [secondaryCityField, , secondaryCityHelpers] = useField<SelectOption | undefined>(
    'secondaryCity'
  );
  const [stateField, , stateHelpers] = useField<SelectOption | undefined>('state');
  const [secondaryStateField, , secondaryStateHelpers] = useField<SelectOption | undefined>(
    'secondaryState'
  );
  const [countryField, , countryHelpers] = useField<SelectOption | undefined>('country');
  const [secondaryCountryField, , secondaryCountryHelpers] = useField<SelectOption | undefined>(
    'secondaryCountry'
  );
  const [sectorField, , sectorHelpers] = useField<SelectOption | undefined>('sector');
  const [subSectorField, , subSectorHelpers] = useField<SelectOption | undefined>('subSector');

  const { countries, sectors, subSectors } = useGetFilterData(3);

  const {
    loadPrimaryStateOptions,
    loadSecondaryStateOptions,
    loadPrimaryCityOptions,
    loadSecondaryCityOptions,
  } = useLoadOptions({ countryField, stateField, secondaryCountryField, secondaryStateField });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleAsyncSearch = useCallback(
    debounce(
      (
        query: string,
        callback: (options: SelectOption[]) => void,
        loadOptions: Function,
        setLoading: Function
      ) => {
        if (query.length >= SEARCH_MINIMUM_CHAR_COUNT) {
          setLoading(true);
          loadOptions(query, (options: SelectOption[]) => {
            options.unshift();
            setLoading(false);

            const mappedOptions = options.map((option: SelectOption) => ({
              label: option.label,
              value: option.value,
            }));

            callback(mappedOptions);
          });
        } else {
          callback([]);
        }
      },
      300
    ),
    []
  );

  const handleStateAsyncSearch = (query: string, callback: (options: SelectOption[]) => void) => {
    if (countryField.value?.value) {
      handleAsyncSearch(query, callback, loadPrimaryStateOptions, setIsLoadingState);
    } else {
      setTimeout(() => callback([]), 1000);
    }
  };

  const handleSecondaryStateAsyncSearch = (
    query: string,
    callback: (options: SelectOption[]) => void
  ) => {
    if (countryField.value?.value) {
      handleAsyncSearch(query, callback, loadSecondaryStateOptions, setIsLoadingSecondaryState);
    } else {
      setTimeout(() => callback([]), 1000);
    }
  };

  const handleCityAsyncSearch = (query: string, callback: (options: SelectOption[]) => void) => {
    if (stateField.value?.value) {
      handleAsyncSearch(query, callback, loadPrimaryCityOptions, setIsLoadingCity);
    } else {
      setTimeout(() => callback([]), 1000);
    }
  };

  const handleSecondaryCityAsyncSearch = (
    query: string,
    callback: (options: SelectOption[]) => void
  ) => {
    if (secondaryStateField.value?.value) {
      handleAsyncSearch(query, callback, loadSecondaryCityOptions, setIsLoadingSecondaryCity);
    } else {
      setTimeout(() => callback([]), 1000);
    }
  };

  const handleCountrySelection = (val: string) => {
    resetField(stateField.value, stateHelpers, FORM_INITIAL_VALUES.state as SelectOption);
    resetField(cityField.value, cityHelpers, FORM_INITIAL_VALUES.city as SelectOption);
    resetField(
      secondaryStateField.value,
      secondaryStateHelpers,
      FORM_INITIAL_VALUES.secondaryState as SelectOption
    );
    resetField(
      secondaryCityField.value,
      secondaryCityHelpers,
      FORM_INITIAL_VALUES.secondaryCity as SelectOption
    );
    countryHelpers.setValue({ label: val, value: val });
  };

  useEffect(() => {
    handleDisableNextButton(!areFieldsValid(formikProps));
  }, [formikProps, handleDisableNextButton]);

  return (
    <Styled.Step3>
      <Row className="spacer-bottom">
        <Col span={18}>
          <Typography.Text>Let's start with general company info.</Typography.Text>
        </Col>

        <Col span={6}>
          {/* 
            Currently the button for adding another address is disabled since it is not supported. 
            When this will be changed, please just re-enable the button, 
            the functionality should be already implemented.
          */}

          {/* <Button
            className="add-address-button"
            onClick={() => hasSecondaryAddressHelpers.setValue(true)}
          >
            <PlusOutlined className="plus-icon" />
            Add Another Address
          </Button>
          */}
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={8}>
          <FormItem name="companyName" label="Company Name" required>
            <Input name="companyName" />
          </FormItem>

          <FormItem name="alternativeName" label="Alt Names (optional)">
            <Input name="alternativeName" />
          </FormItem>

          <FormItem name="primaryUrl" label="Primary URL" required>
            <Input name="primaryUrl" disabled />
          </FormItem>

          <FormItem name="secondaryUrl" label="Secondary URL (optional)">
            <Input name="secondaryUrl" />
          </FormItem>
        </Col>

        <Col span={8}>
          <FormItem name="primaryAddress" label="Primary Address (optional)">
            <Input name="primaryAddress" />
          </FormItem>

          <FormItem name="primaryAddressSecondLine" label="Primary Address Cont (optional)">
            <Input name="primaryAddressSecondLine" />
          </FormItem>

          <FormItem name={countryField.name} label="Country" required>
            <Select
              name={countryField.name}
              value={countryField.value?.value}
              onChange={handleCountrySelection}
              showSearch
            >
              {countries.map((country, index) => (
                <Option key={index} value={country.value}>
                  <Typography.Text>{country.label}</Typography.Text>
                </Option>
              ))}
            </Select>
          </FormItem>

          <FormItem name={stateField.name} label="State (optional)">
            <AsyncSingleSelect
              className="async-single-select"
              name={stateField.name}
              value={stateField.value}
              onChange={(val: ValueType<SelectOption>) =>
                stateHelpers.setValue(val as SelectOption)
              }
              loadOptions={handleStateAsyncSearch}
              placeholder=""
              loading={isLoadingState}
              closeMenuOnSelect={true}
              blurInputOnSelect={true}
              controlShouldRenderValue={true}
              components={{ DropdownIndicator }}
              onBlur={() => {
                setTimeout(() => {
                  stateHelpers.setTouched(true, true);
                }, 100);
              }}
            />
          </FormItem>

          <FormItem name={cityField.name} label="City (optional)">
            <AsyncSingleSelect
              className="async-single-select"
              value={cityField.value}
              onChange={(val: ValueType<SelectOption>) => cityHelpers.setValue(val as SelectOption)}
              loadOptions={handleCityAsyncSearch}
              placeholder=""
              loading={isLoadingCity}
              closeMenuOnSelect={true}
              controlShouldRenderValue={true}
              blurInputOnSelect={true}
              components={{ DropdownIndicator }}
              onBlur={() => {
                setTimeout(() => {
                  cityHelpers.setTouched(true, true);
                }, 100);
              }}
            />
          </FormItem>

          <FormItem name="zipCode" label="Postal Code (optional)">
            <Input name="zipCode" />
          </FormItem>

          {hasSecondaryAddressField.value && (
            <>
              <FormItem name="secondaryAddress" label="Secondary Address (optional)">
                <Input name="secondaryAddress" />
              </FormItem>

              <FormItem name="secondaryAddressSecondLine" label="Secondary Address Cont (optional)">
                <Input name="secondaryAddressSecondLine" />
              </FormItem>

              <FormItem name={secondaryCountryField.name} label="Country" required>
                <Select
                  name={secondaryCountryField.name}
                  value={secondaryCountryField.value?.value}
                  getPopupContainer={trigger => trigger.parentElement!}
                  onChange={(val: string) =>
                    secondaryCountryHelpers.setValue({ label: val, value: val })
                  }
                  showSearch
                >
                  {countries.map((country, index) => (
                    <Option key={index} value={country.value}>
                      <Typography.Text>{country.label}</Typography.Text>
                    </Option>
                  ))}
                </Select>
              </FormItem>

              <FormItem name={secondaryStateField.name} label="State (optional)">
                <AsyncSingleSelect
                  className="async-single-select"
                  name={secondaryStateField.name}
                  value={secondaryStateField.value}
                  onChange={(val: ValueType<SelectOption>) =>
                    secondaryStateHelpers.setValue(val as SelectOption)
                  }
                  loadOptions={handleSecondaryStateAsyncSearch}
                  placeholder=""
                  loading={isLoadingSecondaryState}
                  closeMenuOnSelect={true}
                  blurInputOnSelect={true}
                  controlShouldRenderValue={true}
                  components={{ DropdownIndicator }}
                  onBlur={() => {
                    setTimeout(() => {
                      secondaryStateHelpers.setTouched(true, true);
                    }, 100);
                  }}
                />
              </FormItem>

              <FormItem name={secondaryCityField.name} label="City (optional)">
                <AsyncSingleSelect
                  className="async-single-select"
                  value={secondaryCityField.value}
                  onChange={(val: ValueType<SelectOption>) =>
                    secondaryCityHelpers.setValue(val as SelectOption)
                  }
                  loadOptions={handleSecondaryCityAsyncSearch}
                  placeholder=""
                  loading={isLoadingSecondaryCity}
                  closeMenuOnSelect={true}
                  controlShouldRenderValue={true}
                  blurInputOnSelect={true}
                  components={{ DropdownIndicator }}
                  onBlur={() => {
                    setTimeout(() => {
                      secondaryCityHelpers.setTouched(true, true);
                    }, 100);
                  }}
                />
              </FormItem>

              <FormItem name="secondaryZipCode" label="Postal Code (optional)">
                <Input name="secondaryZipCode" />
              </FormItem>
            </>
          )}
        </Col>

        <Col span={8}>
          <FormItem name={sectorField.name} label="Sector" required>
            <Select
              name={sectorField.name}
              value={sectorField.value?.value}
              onChange={(val: string) => sectorHelpers.setValue({ label: val, value: val })}
              showSearch
            >
              {sectors.map((sector, index) => (
                <Option key={index} value={sector.value}>
                  <Typography.Text>{sector.label}</Typography.Text>
                </Option>
              ))}
            </Select>
          </FormItem>

          <FormItem name={subSectorField.name} label="Sub-Sector (optional)">
            <Select
              name={subSectorField.name}
              value={subSectorField.value?.value}
              onChange={(val: string) => subSectorHelpers.setValue({ label: val, value: val })}
              showSearch
            >
              {subSectors.map((subSector, index) => (
                <Option key={index} value={subSector.value}>
                  <Typography.Text>{subSector.label}</Typography.Text>
                </Option>
              ))}
            </Select>
          </FormItem>

          <FormItem name="productCategory" label="Product Category (optional)">
            <ProductCategoryField />
          </FormItem>

          <FormItem name="ftes" label="Number of Employees (optional)">
            <InputNumberField id="ftes" value="" />
          </FormItem>
        </Col>
      </Row>
    </Styled.Step3>
  );
};

export default React.memo(AddCompanyStep3);
