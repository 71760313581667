import queryString from 'query-string';
import { Dictionary } from 'lodash';
// models
import { SaveSearchAsListPayload } from '@optx/models/api/WatchLists';
import { SearchPayloadRequest } from '@optx/models/api/contacts';
import { FilterExportPayload } from '@models/api/exportCSV';
// utils
import { mapSearchQuery } from '@optx/utils/search';
import { cleanPayload } from '@utils/api/cleanPayload';
// local
import { SourcingInfo } from '@optx/screens/App/AllReports/components/CompanySearchDropdown/SearchMenu/interfaces';
import ApiClient, { API_URL } from './axios-client';
import SearchService from './SearchService';

export class AnalystService {
  static client: ApiClient = new ApiClient(`${API_URL}/analyst`);

  static getDasboard = (query: string) => {
    return AnalystService.client.get(`/get_dashboard?${query}`);
  };

  static getMapInfo = (query: string) => {
    return AnalystService.client.get(`/map_info?${query}`);
  };

  static getEmailStatistics = (query: string) => {
    return AnalystService.client.get(`/email_statistic?${query}`);
  };

  static getSmartTouchScore = (query: string) => {
    return AnalystService.client.get(`/smart_score?${query}`);
  };

  static getOPTXStats = (query: string) => {
    return AnalystService.client.get(`/optx_stats?${query}`);
  };

  static getPipelineInfo = (query: string) => {
    return AnalystService.client.get(`/pipeline_statistic?${query}`);
  };

  static getETMergeHistory = (query: string) => {
    return AnalystService.client.get(`/et_merge_history?${query}`);
  };

  static getETAllTimeHighlights = (query: string) => {
    return AnalystService.client.get(`/et_alltime_highlights?${query}`);
  };

  static getPipelineReportsCompany = (query: string) => {
    return AnalystService.client.get(`/pipeline_report/company?${query}`);
  };

  static getPipelineReportsCompanyCount = (query: string) => {
    return AnalystService.client.get(`/pipeline_report/company/count?${query}`);
  };

  static getPipelineReportsCard = (query: string) => {
    return AnalystService.client.get(`/pipeline_report/card?${query}`);
  };

  static getSourcingOutreach = (query: string) => {
    return AnalystService.client.get(`/sourcing_outreach?${query}`);
  };

  static getCompaniesListOutReach = (searchData: SearchPayloadRequest) => {
    const payload: Dictionary<string | number | string[]> = mapSearchQuery(searchData);

    if (searchData.listType === 'combined' && searchData.fromSavedList) {
      payload.combined_search_id = searchData.fromSavedList;
    }

    if (payload.analyst_id && typeof payload.analyst_id === 'string') {
      payload.analyst_id = payload.analyst_id.split(',');
    }

    const updatedPayload = cleanPayload(payload);

    return AnalystService.client.post('/sourcing_outreach/detail', updatedPayload);
  };

  static getCompanieListOutReachCount = (searchData: any) => {
    const payload: Dictionary<string | number | string[]> = mapSearchQuery(searchData);

    if (searchData.listType === 'combined' && searchData.fromSavedList) {
      payload.combined_search_id = searchData.fromSavedList;
    }

    if (payload.analyst_id && typeof payload.analyst_id === 'string') {
      payload.analyst_id = payload.analyst_id.split(',');
    }

    const updatedPayload = cleanPayload(payload);

    return AnalystService.client.post('/sourcing_outreach/detail/count', updatedPayload);
  };

  static getAnalystChecklistInsight = (query: string) => {
    return AnalystService.client.get(`/checklist_insights?${query}`);
  };

  static getCompanyChecklistInsight = (query: string) => {
    return AnalystService.client.get(`/checklist_insights/companies?${query}`);
  };

  static getCompanyCountChecklistInsight = (query: string) => {
    return AnalystService.client.get(`/checklist_insights/companies/count?${query}`);
  };

  static getCardInfoChecklistInsight = (query: string) => {
    return AnalystService.client.get(`/checklist_insights/cards?${query}`);
  };

  static getCompanyActivity = () => {
    return AnalystService.client.get('/company_activity');
  };

  static getAnalystHomeInsightsStats = () => {
    return AnalystService.client.get('/home/v1?source_tag_filter=et-1-0');
  };

  static getAnalystHomeFilters = () => {
    return AnalystService.client.get('/home/v1/data_settings');
  };

  static getAnalystHomeTouchActivity = (payload: { date_lower: string; date_upper: string }) => {
    const query = queryString.stringify(payload, { arrayFormat: 'comma' });

    return AnalystService.client.get('/home/v1/touch_activity?' + query);
  };

  static getAnalystHomeActivity = (payload: { ddate_lower: string; ddate_upper: string }) => {
    const query = queryString.stringify(payload, { arrayFormat: 'comma' });

    return AnalystService.client.get('/home/v1/activity_insights?' + query);
  };

  static saveAsListPipelineReport = (title: string, searchData: SearchPayloadRequest) => {
    const payload = mapSearchQuery(searchData) as unknown as SaveSearchAsListPayload;

    const query = queryString.stringify(
      { ...payload, list_title: title },
      { arrayFormat: 'comma' }
    );

    return AnalystService.client.post(`/pipeline_report/save_as_list?${query}`);
  };

  static exportChecklistResearch = (
    query: string,
    body: {
      columns: Dictionary<string>;
    },
    isCompany: boolean
  ) => {
    const headers = {
      responseType: 'blob',
      ...SearchService.searchHeaders.headers,
    };

    let url: string = '/checklist_insights/csv_export?';

    if (isCompany) {
      url = '/checklist_insights/companies/csv_export?';
    }

    return AnalystService.client.post<string>(`${url}${query}`, body, {
      headers,
    });
  };

  static exportCompaniesOutReachToCSV = (
    filters: FilterExportPayload,
    body: {
      columns: Dictionary<string>;
    },
    sourcingOutReachInfo?: SourcingInfo
  ) => {
    const cleanFilter = cleanPayload(filters);
    const headers = {
      responseType: 'blob',
      ...SearchService.searchHeaders.headers,
    };

    const updatedBody = {
      ...body,
      ...cleanFilter,
      ...(sourcingOutReachInfo?.analyst_id ? { analyst_id: sourcingOutReachInfo?.analyst_id } : {}),
      ...(sourcingOutReachInfo?.column_name
        ? { column_name: sourcingOutReachInfo?.column_name }
        : {}),
    };

    return AnalystService.client.post<string>('/sourcing_outreach/detail/csv_export', updatedBody, {
      headers,
    });
  };

  // export list of ETIntegration companies to CSV
  static exportPipelineToCSV = (
    query: string,
    body: {
      columns: Dictionary<string>;
    }
  ) => {
    const headers = {
      responseType: 'blob',
      ...SearchService.searchHeaders.headers,
    };

    return AnalystService.client.post<string>(`/pipeline_report/csv_export?${query}`, body, {
      headers,
    });
  };

  // export list of SourcingOutreach data to CSV
  static exportSourcingOutreachToCSV = (
    query: string,
    body: {
      columns: Dictionary<string>;
    }
  ) => {
    const headers = {
      responseType: 'blob',
      ...SearchService.searchHeaders.headers,
    };

    return AnalystService.client.post<string>(`/sourcing_outreach/csv_export?${query}`, body, {
      headers,
    });
  };
}
