import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useField } from 'formik';
import { Form } from 'formik-antd';
import { Row, Col, Typography, Button } from 'antd';
import { isEqual, orderBy } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { MenuInfo } from 'rc-menu/lib/interface';
// models
import { TouchFieldProps, FinancialField } from './interface';
import { SelectOption } from '@optx/models/Option';
import { FinancialFieldRangeValue } from '@optx/models/Company';
// constants
import { ACTUAL_SHORT_CHAR, ESTIMATED_SHORT_CHAR } from './constants';
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
import { numberPercentageMatchRegex } from '@optx/constants/regex';
// utils
import { isOptionChecked } from '@optx/utils/option';
// redux
import { getFinancialFilterOptions } from '@optx/redux/company/filters/selectors';
import {
  actions as modalActions,
  selectors as modalSelectors,
} from '@redux/ui/modals/company-touches';
import {
  actions as companyEditAllActions,
  selectors as companyEditAllSelectors,
} from '@optx/features/edit-all-info/state';
// components
import AddYearBox from './AddYearBox';
import InputWithDropdown from '@optx/shared/view/molecules/FormFields/InputWithDropdown';
// styles
import Styled from './MultiInputField.styled';

const validatePercentageFields = ['gm'];

const adjustedValues = (array: Array<FinancialField>) => {
  const newValue = array.map((item: FinancialField) => {
    return {
      ...item,
      value: item.value === '' ? DEFAULT_EMPTY_VALUE : item.value,
    };
  });

  return newValue;
};

const MultiInputField: React.FC<TouchFieldProps> = ({ field, fieldUsedFor, tabKey }) => {
  const [selected, , setSelectedOption] = useField<Array<FinancialField>>(field.id);
  const [amount, setAmount] = useState<Array<FinancialField>>([]);
  const [addYear, setAddYear] = useState<boolean | string>(false);
  const [editYear, setEditYear] = useState<number | undefined>(undefined);
  const [editEstimated, setEditEstimated] = useState<boolean | undefined>(undefined);
  const arrOptions: SelectOption[] = useSelector(getFinancialFilterOptions('last_arr_value'));
  const revenueOptions: SelectOption[] = useSelector(
    getFinancialFilterOptions('last_rev_update_amount')
  );
  const ebitdaOptions = useSelector(getFinancialFilterOptions('ebitda_numeric'));
  const gmOptions = useSelector(getFinancialFilterOptions('last_gross_margin'));

  const dispatch = useDispatch();

  const usedFor = fieldUsedFor ?? 'editAllInfo';

  const listSelectors = {
    companyTouches: modalSelectors.getDisableSaveBtnFor,
    editAllInfo: companyEditAllSelectors.getDisableSaveBtnFor,
  };
  const saveBtnStatus = useSelector(listSelectors[usedFor]);

  const fieldOptions = useMemo(
    () => ({
      annual_recurring_revenue: arrOptions,
      revenue: revenueOptions,
      ebitda: ebitdaOptions,
      gm: gmOptions,
    }),
    [arrOptions, ebitdaOptions, gmOptions, revenueOptions]
  );

  const options = useMemo(
    () => fieldOptions[field.id as keyof typeof fieldOptions],
    [field.id, fieldOptions]
  );

  const handleAmountChange = useCallback(
    (value: string, inputName: string, year?: number) => {
      const newSelected = amount?.map((item: any) => {
        if (item.year + (item.estimated ? ESTIMATED_SHORT_CHAR : ACTUAL_SHORT_CHAR) === inputName) {
          let itemValue: string | FinancialFieldRangeValue = value;

          if (options && isOptionChecked(value, options)) {
            const selectedOption = options.find(option => isEqual(option.label, value));

            itemValue = selectedOption!.rangeValue ?? selectedOption!.value;
          }

          return { ...item, value: itemValue };
        }

        return item;
      });

      setAmount(newSelected);

      // avoid update formik value when user types '.' because the
      // useEffect will format and remove it from the input
      if (!value.endsWith('.')) {
        const newValue = adjustedValues(newSelected as Array<FinancialField>);

        const currentValue = newValue.find(item => item.year === year);
        const currentStateValue = newSelected?.find(item => item.year === year);

        if (
          (newValue && currentValue?.value !== DEFAULT_EMPTY_VALUE) ||
          currentStateValue.value === ''
        ) {
          setSelectedOption.setValue(newValue);
        }
      }
    },
    [amount, options, setSelectedOption]
  );

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, inputName: string, year: number) => {
      const { value: inputValue } = e.target;
      handleAmountChange(inputValue, inputName, year);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selected.value, setSelectedOption]
  );

  const handleSelectOption = (event: MenuInfo, index: number | undefined, selectedYear: string) => {
    handleAmountChange(event.key, selectedYear);
  };

  const addYearHandler = () => {
    setEditYear(undefined);
    setEditEstimated(undefined);
    setAddYear(!addYear);

    const btnStatus = !addYear ? field.id : null;

    if (fieldUsedFor === 'companyTouches') {
      dispatch(modalActions.changeSaveBtnStatus(btnStatus));
    } else {
      dispatch(companyEditAllActions.changeSaveBtnStatus(btnStatus));
    }
  };

  const editYearHandler = useCallback(
    (year: number, estimated: boolean) => {
      setEditYear(year);
      setEditEstimated(estimated);
      setAddYear(true);

      if (fieldUsedFor === 'companyTouches') {
        dispatch(modalActions.changeSaveBtnStatus(field.id));
      } else {
        dispatch(companyEditAllActions.changeSaveBtnStatus(field.id));
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [field]
  );

  const saveYear = useCallback(
    (financial: FinancialField[]) => {
      const updatedFinancial = orderBy(financial, 'year', 'desc');

      setAmount(updatedFinancial);
      setAddYear(false);

      if (fieldUsedFor === 'companyTouches') {
        dispatch(modalActions.changeSaveBtnStatus(null));
      } else {
        dispatch(companyEditAllActions.changeSaveBtnStatus(null));
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [field, setSelectedOption]
  );

  const getInputLabel = useCallback(
    (value: number | string | FinancialFieldRangeValue) => {
      if (typeof value === 'number') {
        return value.toString();
      }

      if (value === '') {
        return value;
      }

      const selectedOption = options.find(item =>
        isEqual(typeof value === 'string' ? item.value : item.rangeValue, value)
      );

      if (options && selectedOption) {
        return selectedOption?.label ?? DEFAULT_EMPTY_VALUE;
      }

      return value as string;
    },
    [options]
  );

  const validateField = useCallback(
    (value: string | number | FinancialFieldRangeValue) => {
      const commonCheck =
        value &&
        Number.isNaN(Number(value)) &&
        value !== DEFAULT_EMPTY_VALUE &&
        !options.find(item =>
          isEqual(typeof value === 'object' ? item.rangeValue : item.value, value)
        );

      return (commonCheck && !validatePercentageFields.includes(field.id)) ||
        (commonCheck &&
          validatePercentageFields.includes(field.id) &&
          !numberPercentageMatchRegex.test(typeof value === 'string' ? value : ''))
        ? 'has-errors'
        : 'valid-input';
    },
    [options, field.id]
  );

  useEffect(() => {
    if (fieldUsedFor === 'editAllInfo' && selected.value.length) {
      const valueFromForm = selected.value.map((item: FinancialField) => {
        return {
          ...item,
          value: item.value === DEFAULT_EMPTY_VALUE ? '' : item.value,
        };
      });
      setAmount(valueFromForm);
    } else if (field.data) {
      const newValue = field.data.map((item: FinancialField) => {
        return {
          ...item,
          value: item.value === DEFAULT_EMPTY_VALUE ? '' : item.value,
        };
      });

      if (!isEqual(newValue, amount)) {
        setAmount(newValue);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field]);

  useEffect(() => {
    if (selected.value && selected.value.length) {
      const newValue = selected.value.map((item: FinancialField) => {
        return {
          ...item,
          value: item.value === DEFAULT_EMPTY_VALUE ? '' : item.value,
        };
      });

      if (!isEqual(newValue, amount)) {
        setAmount(newValue);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected.value]);

  useEffect(() => {
    if (amount && fieldUsedFor === 'companyTouches') {
      const newValue = adjustedValues(amount);

      if (!isEqual(newValue, selected.value)) {
        setSelectedOption.setValue(newValue);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount]);

  useEffect(() => {
    if (saveBtnStatus && saveBtnStatus !== field.id) {
      setAddYear(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveBtnStatus]);

  return (
    <Col span={24}>
      <Form.Item name={field.id}>
        <Row justify="space-between">
          <Col>
            <Typography.Title>{field.label}</Typography.Title>
          </Col>
          {field.editable && tabKey === 'editAllFieldsTab' && (
            <Col>
              <Button onClick={() => addYearHandler()} className="link-btn" tabIndex={-1}>
                +Add Years
              </Button>
            </Col>
          )}
        </Row>
        <Styled.FinancialRow gutter={[16, 16]}>
          {amount?.map((item: FinancialField) => {
            const yearFullName =
              item.year + (item.estimated ? ESTIMATED_SHORT_CHAR : ACTUAL_SHORT_CHAR);
            const validationClass = validateField(item.value);
            const className = `${validationClass} financial-validation`.trim();

            return (
              <Col span={6} key={yearFullName}>
                <Typography.Text>
                  <Styled.FinancialYear
                    role="presentation"
                    onClick={e => {
                      editYearHandler(item.year, item.estimated);
                    }}
                  >
                    {yearFullName}
                    <Styled.PenIcon iconName="pen" $visible={true} />
                  </Styled.FinancialYear>
                </Typography.Text>
                <div className={className}>
                  <InputWithDropdown
                    options={options}
                    placeholder={field.placeholder}
                    value={getInputLabel(item.value)}
                    onInputChange={e => handleChange(e, yearFullName, item.year)}
                    onSelectOption={(event, index) =>
                      handleSelectOption(event, index, yearFullName)
                    }
                    closeOnSelect={true}
                    hideDropdownOnFocusOut={true}
                  />
                </div>
              </Col>
            );
          })}
        </Styled.FinancialRow>
      </Form.Item>
      {addYear && field.editable && (
        <AddYearBox
          saveYearHandler={saveYear}
          addYearHandler={addYearHandler}
          financialData={amount}
          selectedYear={editYear}
          isEstimated={editEstimated}
        />
      )}
    </Col>
  );
};

export default React.memo(MultiInputField);
