import { generateDynamicColumnKeys } from '@optx/utils/columns';

export const SEARCH_MINIMUM_CHAR_COUNT = 3;
export const STATE_SEARCH_ENDPOINT = '/search/state';
export const CITY_SEARCH_ENDPOINT = '/search/city';
export const PRODUCT_CATEGORY_SEARCH_ENDPOINT = '/search/product_category';
export const COMPANY_NAME_SEARCH_ENDPOINT = '/search/company_name';
export const LAST_INVESTORS_SEARCH_ENDPOINT = '/search/last_investors';

export const FINANCIAL_YEARS = [new Date().getFullYear(), new Date().getFullYear() - 1];
export const YEARS_LABELS = {
  current: new Date().getFullYear().toString(),
  future: (new Date().getFullYear() + 1).toString(),
  'past 1 year': (new Date().getFullYear() - 1).toString(),
  'past 2 years': (new Date().getFullYear() - 2).toString(),
};
export const generateArrCurrentColumnKey = `${generateDynamicColumnKeys('arr', 'current')}`;
export const generateArrPast1YearColumnKey = `${generateDynamicColumnKeys('arr', 'past 1 year')}`;

export const generateEbitdaCurrentColumnKey = `${generateDynamicColumnKeys('ebitda', 'current')}`;
export const generateEbitdaPast1YearColumnKey = `${generateDynamicColumnKeys(
  'ebitda',
  'past 1 year'
)}`;
export const generateGrowthCurrentColumnKey = `${generateDynamicColumnKeys('growth', 'current')}`;
export const generateGrowthPast1YearColumnKey = `${generateDynamicColumnKeys(
  'growth',
  'past 1 year'
)}`;
