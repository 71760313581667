import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// components
import { exportFileCSV } from '@utils/csvHandler';
// redux
import { selectors as userListSelectors } from '@redux/user/user-list';
import { actions as favoriteListsActions } from '@redux/favorite-lists/lists';
import { getFilterListId } from '@redux/lists/details/selectors';
import { clearCompanyList } from '@redux/lists/details/actions';
import { actions as pipelineActions } from '@redux/my-pipeline';
import DropdownDot from '@components/feature/drop-down/saved-searches/ListActions';
import { SourceScrubList } from '@optx/models/SourceScrubList';
import { ListAccessRights } from '@optx/models/WatchList';
import { CompanyService } from '@optx/services/api';
import { columns } from '@optx/components/common/table/Companies/columns/virtual-column-config';

const TableCellActions: React.FC<{ row: SourceScrubList }> = ({ row }) => {
  const { id, title } = row;

  const dispatch = useDispatch();

  const currentFilteredListId = useSelector(getFilterListId);
  const userListShare = useSelector(userListSelectors.getUserList);

  const handleRenameList = (title: string, listId: number | string) => {
    dispatch(favoriteListsActions.renameFavoriteList({ title, list_id: listId }));
  };

  const handleClearFilterList = () => dispatch(clearCompanyList());

  const handleDeleteListFavorites = (listId: number | string) => {
    // clear current list before deleting it
    if (currentFilteredListId.toString() === listId.toString()) {
      handleClearFilterList();
    }

    dispatch(favoriteListsActions.deleteFavoriteList(listId));
  };

  const handleExportListFavorites = (
    listId: string,
    fileName: string,
    includeDescription = false
  ) => {
    const companyData = { ss_list_id: listId };

    dispatch(pipelineActions.setLoading(true));
    exportFileCSV(
      fileName,
      companyData,
      columns,
      CompanyService.exportToCSV,
      includeDescription,
      true
    ).then(() => {
      dispatch(pipelineActions.setLoading(false));
    });
  };

  return (
    <>
      <DropdownDot
        type="list"
        isRight
        listId={Number(id)}
        title={title}
        userListShare={userListShare}
        handleDeleteList={handleDeleteListFavorites}
        handleRenameList={handleRenameList}
        onDownloadList={handleExportListFavorites}
        accessRights={ListAccessRights.ReadOnly}
      />
    </>
  );
};

export default TableCellActions;
