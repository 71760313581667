import moment from 'moment';
import { isEqual } from 'lodash';
import numeral from 'numeral';
// models
import { SelectOption } from '@optx/models/Option';
import { DatePresented } from '@optx/models/Company';
// interfaces
import {
  ChangedCompanyField,
  FinancialField,
  FieldChangedValue,
} from '@features/company/audit-trail/state/interfaces';
// constants
import { NUMBER_FORMAT } from '@optx/constants/format/number';
import { formatMoney } from './format';
import { abbreviateValue, roundNumber } from './number';
import { isOptionChecked } from './option';
// utils
import { showRationaleForStage, showOpinionForNextSteps, formatNumber } from '@optx/utils/helpers';
import { highlightText } from './text';

export const renderAfterValue = (
  field: ChangedCompanyField,
  isMoney: boolean,
  options?: SelectOption[]
) => {
  const afterValue = field.after_value;

  if (
    field.field_changed === 'Revenue' ||
    field.field_changed === 'Last Revenue' ||
    field.field_changed === 'ARR' ||
    field.field_changed === 'EBITDA' ||
    field.field_changed === 'GM %'
  ) {
    if (afterValue) {
      let value = (afterValue as FinancialField).value;
      const isFromDropdown = isOptionChecked(value, options);

      if (value === null) {
        return 'None';
      } else if (typeof value === 'number' && !isFromDropdown) {
        field.field_changed === 'GM %'
          ? (value = `${formatMoney(value, true)}%`)
          : (value = `$${formatMoney(value, true)}`);
      } else if (typeof value === 'object' && isFromDropdown) {
        value = options?.find(option => isEqual(option.rangeValue, value))?.label ?? null;
      }

      return (
        <>
          <span style={highlightText(value as string)}>{`${value} `}</span>
          <span>in {moment((afterValue as FinancialField)?.year?.toString()).format('YYYY')}</span>
        </>
      );
    }
  }

  if (field.field_changed === 'Date Presented') {
    if (afterValue === null) {
      return 'None';
    }

    const formatedValue = (afterValue as DatePresented[])
      .map(date => moment(date.date as string).format('DD MMM, YYYY'))
      .join(', ');

    return formatedValue;
  }

  if (field.field_changed === 'Ic Status Date' || field.field_changed === 'Expected Close Date') {
    return `${moment(afterValue as string).format('DD MMM, YYYY')}`;
  }

  if (field.field_changed === 'Product Category') {
    return afterValue === '[]' || afterValue === null ? 'None' : afterValue;
  }

  if (
    field.field_changed === 'US Website Rank' ||
    field.field_changed === 'WW Website Rank' ||
    field.field_changed === 'Crunchbase Rank' ||
    field.field_changed === 'G2 Reviews' ||
    field.field_changed === 'Capterra Reviews' ||
    field.field_changed === 'GD Reviews' ||
    field.field_changed === 'Twitter Followers'
  ) {
    if (afterValue === null) {
      return 'None';
    }

    return numeral(afterValue).format('0,0');
  }

  if (field.field_changed === 'Number of Employees') {
    if (afterValue === null) {
      return 'None';
    }

    return abbreviateValue(afterValue?.toString(), undefined, undefined, true);
  }

  if (field.field_changed === 'Optx Score' && afterValue === null) {
    return 'None';
  }

  if (Array.isArray(afterValue)) {
    if (afterValue.length === 0) {
      return 'None';
    }

    const formattedValue = (afterValue as string[]).join(', ');

    return formattedValue;
  }

  if (typeof afterValue === 'boolean') {
    return afterValue ? 'Yes' : 'No';
  }

  if (field.field_changed === FieldChangedValue.lastFundingDate) {
    return moment(afterValue as string).format('DD MMM, YYYY');
  }

  if (field.field_changed === FieldChangedValue.askingAmount) {
    if (afterValue === null) return 'None';

    return roundNumber(afterValue as string, NUMBER_FORMAT);
  }

  if (field.field_changed === FieldChangedValue.capitalRaised) {
    if (afterValue === null) {
      return 'None';
    }

    if (typeof afterValue === 'string' && afterValue.includes(' (plus Unknown)')) {
      const number = afterValue.split(' (')[0];

      return `${formatMoney(number)} (plus Unknown)`;
    }

    return formatMoney(afterValue as number);
  }

  if (field.field_changed === FieldChangedValue.sizeMultiple) {
    if (afterValue === null) {
      return 'None';
    }

    return formatNumber(afterValue as number);
  }

  // eslint-disable-next-line no-nested-ternary
  return isMoney && afterValue !== null
    ? formatMoney(afterValue as number)
    : afterValue !== null
    ? handleTypeOfValue(afterValue as string | number | FinancialField)
    : 'None';
};

const handleTypeOfValue = (value: string | number | FinancialField) => {
  if (typeof value === 'number') {
    return numeral(value).format(NUMBER_FORMAT);
  }

  return value;
};

export const renderFormerValue = (
  field: ChangedCompanyField,
  isMoney: boolean,
  options?: SelectOption[]
) => {
  const beforeValue = field.before_value;

  if (field.field_changed === FieldChangedValue.lastFundingDate && beforeValue) {
    return `(was ${moment(beforeValue as string).format('DD MMM, YYYY')})`;
  }

  if (field.field_changed === FieldChangedValue.askingAmount) {
    if ([undefined, 'null', '0'].includes(beforeValue?.toString())) {
      return '(was None)';
    }

    if (beforeValue) {
      return `(was ${roundNumber(beforeValue as string, NUMBER_FORMAT)})`;
    }
  }

  if (field.field_changed === 'Date Presented') {
    if (beforeValue === null) {
      return '(was None)';
    }

    const formatedValue = (beforeValue as DatePresented[])
      .map(date => moment(date.date as string).format('DD MMM, YYYY'))
      .join(', ');

    return `(was ${formatedValue})`;
  }

  if (field.field_changed === 'Ic Status Date' || field.field_changed === 'Expected Close Date') {
    if (beforeValue === null) {
      return '(was None)';
    }

    return `(was ${moment(beforeValue as string).format('DD MMM, YYYY')})`;
  }

  if (field.field_changed === 'Product Category') {
    return `(was ${beforeValue === '[]' || beforeValue === null ? 'None' : beforeValue})`;
  }

  if (
    field.field_changed === 'US Website Rank' ||
    field.field_changed === 'WW Website Rank' ||
    field.field_changed === 'Crunchbase Rank' ||
    field.field_changed === 'G2 Reviews' ||
    field.field_changed === 'Capterra Reviews' ||
    field.field_changed === 'GD Reviews' ||
    field.field_changed === 'Twitter Followers'
  ) {
    if (beforeValue === null) {
      return '(was None)';
    }

    return `(was ${numeral(beforeValue).format('0,0')})`;
  }

  if (field.field_changed === 'Number of Employees') {
    if (beforeValue === null) {
      return '(was None)';
    }

    return `(was ${abbreviateValue(beforeValue?.toString(), undefined, undefined, true)})`;
  }

  if (field.field_changed === 'Optx Score' && beforeValue === null) {
    return '(was None)';
  }

  if (
    field.field_changed === 'Revenue' ||
    field.field_changed === 'Last Revenue' ||
    field.field_changed === 'ARR' ||
    field.field_changed === 'EBITDA' ||
    field.field_changed === 'GM %'
  ) {
    if (beforeValue && !isEmptyRevenue(field)) {
      let value = (beforeValue as FinancialField).value;
      const isFromDropdown = isOptionChecked(value, options);

      if (typeof value === 'number' && !isFromDropdown) {
        field.field_changed === 'GM %'
          ? (value = `${formatMoney(value, true)}%`)
          : (value = `$${formatMoney(value, true)}`);
      } else if (typeof value === 'object' && isFromDropdown) {
        value = options?.find(option => isEqual(option.rangeValue, value))?.label ?? null;
      }

      return (
        <>
          <span>
            (was <span style={highlightText(value as string)}>{`${value}`}</span> in{' '}
          </span>
          <span>{moment((beforeValue as FinancialField)?.year?.toString()).format('YYYY')})</span>
        </>
      );
    }

    if (beforeValue === null) {
      return '(was None)';
    }

    return '';
  }

  if (field.field_changed === FieldChangedValue.yearFounded) {
    if (beforeValue === null) {
      return '(was None)';
    }

    return `(was ${beforeValue?.toString()})`;
  }

  if (field.field_changed === FieldChangedValue.stage) {
    if (beforeValue === null) {
      return '(was None)';
    }

    return `(was ${beforeValue}${showRationaleForStage(beforeValue as string) ? '' : ')'}`;
  }

  if (field.field_changed === FieldChangedValue.nextSteps) {
    if (beforeValue === null) {
      return '(was None)';
    }

    return `(was ${beforeValue}${
      showOpinionForNextSteps(beforeValue as string) && field.before_value_opinion ? '' : ')'
    }`;
  }

  if (field.field_changed === FieldChangedValue.capitalRaised) {
    if (beforeValue === null) {
      return '(was None)';
    }

    if (typeof beforeValue === 'string' && beforeValue.includes(' (plus Unknown)')) {
      const number = beforeValue.split(' (')[0];

      return `(was ${formatMoney(number)} (plus Unknown))`;
    }

    return `(was ${formatMoney(beforeValue as number)})`;
  }

  if (field.field_changed === FieldChangedValue.sizeMultiple) {
    if (beforeValue === null) {
      return '(was None)';
    }

    return `(was ${formatNumber(beforeValue as number)})`;
  }

  if (Array.isArray(beforeValue)) {
    if (beforeValue.length === 0) {
      return '(was None)';
    }

    const formattedValue = (beforeValue as string[]).join(', ');

    return `(was ${formattedValue})`;
  }

  if (typeof beforeValue === 'boolean') {
    return `(was ${beforeValue ? 'Yes' : 'No'})`;
  }

  if (beforeValue || beforeValue === 0) {
    if (isMoney) {
      return `(was ${formatMoney(beforeValue as number)})`;
    }

    return `(was ${handleTypeOfValue(beforeValue as string | number | FinancialField)})`;
  }

  if (beforeValue === null) {
    return '(was None)';
  }

  return null;
};

/**
 * render the 'after_optx' value from history field
 * @param {ChangedCompanyField} field
 */
export const renderAfterOPTX = (field: ChangedCompanyField) => {
  const afterOPTX = field.after_optx;

  if (afterOPTX || afterOPTX === 0) {
    return handleTypeOfValue(afterOPTX);
  }

  if (afterOPTX === null) {
    return 'None';
  }

  return null;
};

/**
 * render the 'before_optx' value from history field
 * @param {ChangedCompanyField} field
 */
export const renderFormerOPTX = (field: ChangedCompanyField) => {
  const beforeOPTX = field.before_optx;

  if (beforeOPTX || beforeOPTX === 0) {
    return `(was ${handleTypeOfValue(beforeOPTX)})`;
  }

  if (beforeOPTX === null) {
    return '(was None)';
  }

  return null;
};

/**
 * get internal filter value based on active tab
 * @param {string} tabKey active tab key
 */
export const getInternalValue = (tabKey: string) => {
  switch (tabKey) {
    case '2': {
      return 'False';
    }

    case '3': {
      return 'True';
    }

    default: {
      return undefined;
    }
  }
};

/**
 * get optx filter value based on active tab
 * @param {string} tabKey active tab key
 */
export const getOPTXValue = (tabKey: string) => {
  switch (tabKey) {
    case '2': {
      return 'False';
    }

    case '3': {
      return 'False';
    }

    case '4': {
      return 'True';
    }

    default: {
      return undefined;
    }
  }
};

export const isEmptyRevenue = (field: ChangedCompanyField) => {
  if (field.field_changed === 'Last Revenue')
    return (
      isEqual(field.before_value, { value: 0, year: null }) ||
      isEqual(field.before_value, { value: null, year: 0 }) ||
      field.after_value === null ||
      field.before_value === null
    );

  return false;
};
