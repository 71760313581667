import { each } from 'lodash';
import { ValueType } from 'react-select';
// models
import { FilterMultiSelectOption, APIMultiSelectOption, SelectOption } from '@optx/models/Option';
import { FilterSource, Filter, MultiselectMappedFilter } from '@optx/models/filters';
import { mapToSelectOption } from '../option';
// constants
import { FILTER_TOUCHES } from '@constants/filters';

/**
 * compares the selected values with the entries from the helper filter
 * if matches are found returns the options
 * @param values all selected values of multi select filter
 * @param helperName name of the helper filter
 * @param source filters source data
 */
export const checkHelperValues = (
  values: Array<APIMultiSelectOption>,
  helperName: string,
  source: FilterSource<Filter<any>>
) => {
  let helperData = [] as Array<ValueType<APIMultiSelectOption>>;
  const helpersValues = [] as Array<ValueType<APIMultiSelectOption>>;
  // get the associated helper filter
  each(source.data, filter => {
    if (filter.column === helperName) {
      helperData = filter.data;

      return false;
    }

    return true;
  });

  helperData.forEach(item => {
    if (values!.length >= item!.entries!.length) {
      let addOption = true;
      const valueList = [] as Array<string>;
      // compile the selected values of the multi select into an array
      // which contains only the value properties, making it easier to compare values
      values.forEach(option => {
        valueList.push(option.value);
      });
      // check if values from helper filter find
      // themselves in the selected value list
      each(item?.entries, (entry: { label: string; value: string }) => {
        if (!valueList.includes(entry.value)) {
          addOption = false;

          return false;
        }

        return true;
      });

      if (addOption) {
        helpersValues.push(item);
      }
    }
  });
  const newValues: ValueType<APIMultiSelectOption> = helpersValues.map(option => {
    return mapToSelectOption(option as string | APIMultiSelectOption<string>, undefined);
  });

  return newValues;
};

/**
 * when removing or deselecting an option, check if this value matches
 * any of the entries from the selected options of the helper filter and return the index
 * @param selectedValue the option that was removed or deselected
 * @param filterValues the selected values from the helper filter
 */
export const helperValueIndex = (
  selectedValue: string,
  filterValues: Array<FilterMultiSelectOption>
) => {
  let removeIndexValue: undefined | number;

  if (filterValues && filterValues.length) {
    each(filterValues, (filterValue, index) => {
      each(filterValue.entries, entry => {
        if (entry.value === selectedValue) {
          removeIndexValue = index;

          return false;
        }

        return true;
      });

      if (removeIndexValue) {
        return false;
      }

      return true;
    });
  }

  return removeIndexValue;
};

export function arrayUnion(firstArray: SelectOption[], secondArray: SelectOption[]) {
  const union = [...firstArray, ...secondArray].reduce((acc: SelectOption[], obj: SelectOption) => {
    const foundIndex = acc.findIndex((item: { value: string }) => item.value === obj.value);

    if (foundIndex === -1) {
      acc.push(obj);
    }

    return acc;
  }, []);

  return union;
}

export function removeElementByValue(arr: SelectOption[], value: string) {
  const index = arr.findIndex((obj: { value: string }) => obj.value === value);

  if (index !== -1) {
    arr.splice(index, 1);
  }

  return arr;
}

export function removeMultipleElements(arr1: SelectOption[], arr2: SelectOption[]) {
  const valuesToRemove = arr2.map((obj: { value: string }) => obj.value);

  return arr1.filter((obj: { value: string }) => !valuesToRemove.includes(obj.value));
}

export function normalizeFilter(filter: any) {
  let filterCopy = { ...filter };

  if (filterCopy.company_owner_id !== undefined && Array.isArray(filterCopy.company_owner_id)) {
    filterCopy = {
      ...filterCopy,
      company_owner_id: filterCopy.company_owner_id.filter(
        (item: { value: string; label: string }) =>
          item.value !== 'active sourcing team' && item.value !== 'everybody else'
      ),
    };
  }

  if (filterCopy.psg_attendees !== undefined && Array.isArray(filterCopy.psg_attendees)) {
    const excludedValues = new Set(['uk team group', 'us team group', 'other group']);
    filterCopy = {
      ...filterCopy,
      psg_attendees: filterCopy.psg_attendees.filter(
        (item: { value: string; label: string }) => !excludedValues.has(item.value)
      ),
    };
  }

  return filterCopy;
}

/**
 * There are any empty values in the filter object
 * the function return only non empty values
 * @param data data of object
 */
export const cleanObjectWithEmptyValues = (data: any) => {
  for (const propName in data) {
    if (
      data[propName] === null ||
      data[propName] === undefined ||
      data[propName]?.length === 0 ||
      data[propName] === '' ||
      (data[propName].length === 1 && data[propName][0] === '')
    ) {
      // eslint-disable-next-line no-param-reassign
      delete data[propName];
    }
  }

  return data;
};

/**
 * function to find and return specific filter from data source
 * @param {FilterSource<Filter<any>>[]} source filter source data
 * @param {string} id name of the filter we want to get data from
 */
export const getFilterSourceData = (source: FilterSource<Filter<any>>[], id: string) => {
  let filter: Filter | undefined;

  if (source.length) {
    each(source, ({ data }) => {
      each(data, columnData => {
        if (columnData.column === id) {
          filter = columnData;

          return false;
        }

        return true;
      });

      if (filter) {
        return false;
      }

      return true;
    });
  }

  return filter;
};

export const getMappedTouchFilters = (touchesParam?: string[]) => {
  const touches = touchesParam ?? FILTER_TOUCHES;

  return touches.map(touch => {
    return {
      disabled: undefined,
      entries: undefined,
      label: touch,
      value: touch,
    };
  }) as MultiselectMappedFilter[];
};
