import React, { useMemo, useRef } from 'react';
import { ActionMeta, GroupedOptionsType, GroupType } from 'react-select';
// models
import { SelectOption } from '@optx/models/Option';
// utils
import { hasGroups, checkOption } from '@optx/utils/select/select';
import { hasBlankOrNoneLabel, hasBlankLabel } from '@optx/utils/helpers';
// local
import Styled from './MultiSelectTags.styled';

interface MultiSelectTagsProps<OptionTypeBase> {
  value?: Array<OptionTypeBase>;
  options: Array<OptionTypeBase>;
  allowSelectAll?: boolean;
  // @ts-ignore
  onChange?: (value: Array<OptionTypeBase>, action: ActionMeta<OptionTypeBase>) => void;
  showKey?: 'label' | 'value';
  hasNoneValue?: boolean;
  selectedOptionsListTitle?: string;
}

const MultiSelectTags = <OptionTypeBase extends SelectOption = SelectOption>({
  value,
  options,
  allowSelectAll,
  onChange,
  showKey = 'label',
  hasNoneValue = false,
  selectedOptionsListTitle,
}: React.PropsWithChildren<MultiSelectTagsProps<OptionTypeBase>>) => {
  const tagsRef = useRef<HTMLDivElement>(null);
  const filterLabel = hasNoneValue ? hasBlankLabel : hasBlankOrNoneLabel;

  // don't show Tags if there is no value.
  const showTags = useMemo(() => {
    if (!value || !(value as Array<OptionTypeBase>).length) {
      return false;
    }

    let allOptions: Array<OptionTypeBase> = [];

    if (hasGroups(options)) {
      options?.forEach((option: OptionTypeBase | GroupType<OptionTypeBase>) => {
        const groupOptions = (option as GroupType<OptionTypeBase>).options;

        if (groupOptions) {
          // grouped options
          allOptions = allOptions.concat(groupOptions);
        } else {
          // single option
          allOptions.push(option as OptionTypeBase);
        }
      });
    } else {
      allOptions = [...(options as Array<OptionTypeBase>)];
    }

    // there is no group so we check if all options are selected.
    return (value as Array<OptionTypeBase>).length !== allOptions.length;
  }, [options, value]);

  if (!value || !showTags) {
    return null;
  }

  // Don't show tags if all options are selected.
  if (allowSelectAll) {
    if (hasGroups(options)) {
      // Options are grouped.
      let allOptions: Array<OptionTypeBase> = [];

      allOptions = (options as unknown as GroupedOptionsType<OptionTypeBase>).reduce(
        (acumulator, group) => acumulator.concat(group.options),
        allOptions
      );

      if (allOptions.length === value?.length) {
        return null;
      }
    } else if ((value as Array<OptionTypeBase>).length === options.length) {
      return null;
    }
  }

  const removeOption = (removedOption: OptionTypeBase) => {
    const newValue = value.filter(option => option !== removedOption);
    onChange && onChange(newValue, { action: 'remove-value', option: removedOption });

    // to find element which is has onKeyDown
    const parentElement = tagsRef.current?.parentNode?.parentNode?.parentNode?.parentElement;
    (parentElement!.querySelector("div[role='presentation']") as HTMLElement)?.focus();
  };

  const getColor = (option: SelectOption) => {
    return 'isValid' in option && !option.isValid ? 'red' : 'blue';
  };

  return (
    <Styled.Wrapper className="multiselect-tags-wrapper" ref={tagsRef}>
      {selectedOptionsListTitle && (
        <Styled.Title level={5}>{selectedOptionsListTitle}</Styled.Title>
      )}
      {value.map(option => {
        if (checkOption(showKey, filterLabel, option)) {
          return '';
        }

        if (
          option.value !== 'active sourcing team' &&
          option.value !== 'everybody else' &&
          option.value !== 'us team group' &&
          option.value !== 'uk team group' &&
          option.value !== 'other group'
        ) {
          return (
            <Styled.Tag
              color={getColor(option)}
              key={option.value}
              closable={!option.disabled}
              onClose={() => removeOption(option)}
            >
              {option[showKey]}
            </Styled.Tag>
          );
        }
      })}
    </Styled.Wrapper>
  );
};

export default MultiSelectTags;
