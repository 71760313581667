import * as yup from 'yup';
// models
import { FormValues } from '../models';
import { SelectOption } from '@models/Option';
// constants
import { FIELD_REQUIRED_ERROR } from '@constants/form';
import { urlRegExp } from '@constants/regex';
import {
  LINK_URL_VALUE_ERROR_MESSAGE,
  ABBREVIATION_ERROR_MESSAGE,
} from '@optx/constants/table/cells';
// utils
import { validatePositiveNumbers } from '@optx/utils/validation';

/**
 * Fields with custom test validation are nullable (even though they're required)
 * because there's a issue that appears in the error text instead of the FIELD_REQUIRED_ERROR
 * saying that the value can't be null. This occurs when changing to a new option from the dropdown.
 * In reality, the fields are still correctly tested and will return an error if conditions are not met.
 */
export default yup.object<FormValues>({
  companyName: yup
    .string()
    .required(FIELD_REQUIRED_ERROR)
    .test('is-not-empty', 'Company name can not contain only spaces', value => {
      return value && value.trim().length > 0;
    })
    .test(
      'name-starts-ends-with-space',
      'Company name can not end or start with empty space',
      value => {
        return typeof value === 'string' && value.trim().length === value.length;
      }
    ),
  primaryUrl: yup
    .string()
    .matches(urlRegExp, LINK_URL_VALUE_ERROR_MESSAGE)
    .required(FIELD_REQUIRED_ERROR),

  alternativeName: yup.string(),
  secondaryUrl: yup
    .string()
    .matches(urlRegExp, LINK_URL_VALUE_ERROR_MESSAGE)
    .test(
      'validSecondaryUrl',
      'Secondary URL must be different from the primary URL!',
      function (value) {
        return this.parent.primaryUrl !== value;
      }
    ),

  primaryAddress: yup.string(),
  primaryAddressSecondLine: yup.string(),
  country: yup
    .object<SelectOption>()
    .test(
      'selectOptionValidation',
      FIELD_REQUIRED_ERROR,
      (value: SelectOption) => value && !!value.value
    )
    .nullable(),
  state: yup.object<SelectOption>().nullable(),
  city: yup.object<SelectOption>().nullable(),
  zipCode: yup.string(),

  hasSecondaryAddress: yup.boolean().required(),

  secondaryAddress: yup.string().when('hasSecondaryAddress', {
    is: true,
    then: yup.string(),
  }),
  secondaryAddressSecondLine: yup.string(),
  secondaryCountry: yup
    .object<SelectOption>()
    .when('hasSecondaryAddress', {
      is: true,
      then: yup
        .object<SelectOption>()
        .test(
          'selectOptionValidation',
          FIELD_REQUIRED_ERROR,
          (value: SelectOption) => value && !!value.value
        )
        .nullable(),
    })
    .nullable(),
  secondaryState: yup
    .object<SelectOption>()
    .when('hasSecondaryAddress', {
      is: true,
      then: yup.object<SelectOption>().nullable(),
    })
    .nullable(),
  secondaryCity: yup
    .object<SelectOption>()
    .when('hasSecondaryAddress', {
      is: true,
      then: yup.object<SelectOption>().nullable(),
    })
    .nullable(),
  secondaryZipCode: yup.string(),

  sector: yup
    .object<SelectOption>()
    .test(
      'selectOptionValidation',
      FIELD_REQUIRED_ERROR,
      (value: SelectOption) => value && !!value.value
    )
    .nullable(),
  subSector: yup.object<SelectOption>().nullable(),
  productCategory: yup.array<SelectOption>().nullable(),
  ftes: yup.mixed().nullable(),

  companyOwner: yup
    .object<SelectOption>()
    .test(
      'selectOptionValidation',
      FIELD_REQUIRED_ERROR,
      (value: SelectOption) => value && !!value.value
    )
    .nullable(),
  etCheck: yup
    .mixed()
    .test('requiredConditional', ABBREVIATION_ERROR_MESSAGE, function (value) {
      return validatePositiveNumbers(value);
    })
    .required(FIELD_REQUIRED_ERROR)
    .nullable(),
  isAddon: yup.object<SelectOption<number>>().nullable(),

  stage: yup
    .object<SelectOption>()
    .test(
      'selectOptionValidation',
      FIELD_REQUIRED_ERROR,
      (value: SelectOption) => value && !!value.value
    )
    .nullable(),
  stageRationale: yup.string().nullable(),
  fund: yup
    .object<SelectOption>()
    .test(
      'selectOptionValidation',
      FIELD_REQUIRED_ERROR,
      (value: SelectOption) => value && !!value.value
    )
    .nullable(),
  addonFor: yup
    .array<SelectOption>()
    .test('addonForValidation', FIELD_REQUIRED_ERROR, function (value: SelectOption[]) {
      const isAddon = this.parent.isAddon?.value === 1;

      return !isAddon || (isAddon && !!value?.length);
    })
    .nullable(),

  rank: yup.object<SelectOption>().nullable(),
  source: yup.object<SelectOption>().nullable(),
  opportunityType: yup
    .object<SelectOption>()
    .test(
      'selectOptionValidation',
      FIELD_REQUIRED_ERROR,
      (value: SelectOption) => value && !!value.value
    )
    .nullable(),
  rationale: yup.string(),
  description: yup.string().required(FIELD_REQUIRED_ERROR),
});
