// const
import { regexDateInterval } from '@optx/constants/regex';

/**
 * Cleans and transforms a given payload object by applying a series of rules to its properties.
 *
 * This function iterates over each property of the input `payload` object and applies the following transformations:
 * 1. Removes properties that are arrays with no elements or arrays with a single `null` element.
 * 2. Converts arrays associated with keys matching a date interval pattern into comma-separated strings.
 * 3. Converts numeric elements within arrays to strings.
 *
 * The resulting object only includes properties with non-null, non-undefined, non-empty values,
 * or values that are explicitly `0` or `false`.
 *
 * @param {Record<string, any>} payload - The input object containing key-value pairs to be cleaned and transformed.
 * @returns {Record<string, string | number | string[]>} - A new object with cleaned and transformed properties.
 */
export const cleanPayload = (
  payload: Record<string, any>
): Record<string, string | number | string[]> => {
  const updatedPayload: Record<string, string | number | string[]> = {};

  for (const key in payload) {
    if (payload.hasOwnProperty(key)) {
      let propertyValue = payload[key];

      // first check to remove empty array or array with 1 item null
      if (
        Array.isArray(payload[key]) &&
        (payload[key].length === 0 || (payload[key].length === 1 && payload[key][0] === null))
      ) {
        propertyValue = null;

        // second check change array to string for date upper/lower filters
      } else if (Array.isArray(payload[key]) && regexDateInterval.test(key)) {
        propertyValue = payload[key].join();

        // third check to convert number to strings from arrays
      } else if (
        key === 'analyst_id' &&
        Array.isArray(payload[key]) &&
        payload[key].length === 1 &&
        payload[key][0] === 'All'
      ) {
        propertyValue = 'All';
      } else if (Array.isArray(payload[key])) {
        propertyValue = payload[key].map((value: any) => {
          if (typeof value === 'number') {
            return value.toString();
          }

          return value;
        });
      }

      if (propertyValue || propertyValue === 0 || propertyValue === false) {
        updatedPayload[key] = propertyValue;
      }
    }
  }

  return updatedPayload;
};
